import {Spinner as BluePrintSpinner} from '@blueprintjs/core'
import styled from 'styled-components'
import React from 'react'


const FadeInSpinner = styled(BluePrintSpinner)`
  @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
  }
  
    animation: fadeIn ${props => props.fadeInSpeed || 3}s;
    animation-delay: ${props => props.delay || 0}s;
    animation-fill-mode: forwards;
`
export default FadeInSpinner